import Vue from 'vue'
import VueRouter from 'vue-router'
import Entrance from '../views/Entrance.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import('@/views/Test.vue')
  // },
  {
    path: '/taskDetailsForIframe',
    name: 'taskDetailsForIframe',
    component: () => import('@/views/forIframe/TaskDetails.vue')
  },
  {
    path: '/',
    name: ''
  },
  {
    path: '/entrance',
    name: 'Entrance',
    component: Entrance
  },
  {
    path: '/faceTask',
    name: 'FaceTask',
    component: () => import('@/views/task/FWJH.vue')
  },
  {
    path: '/taskDetails/:taskSubId/:code',
    name: 'taskDetails',
    component: () => import('@/views/task/TaskDetails.vue')
  },
  {
    path: '/chargeList',
    name: 'chargeList',
    component: () => import('@/views/charge/ChargeList.vue')
  },
  {
    path: '/chargeDetail',
    name: 'chargeDetail',
    component: () => import('@/views/charge/ChargeDetail.vue')
  },
  {
    path: '/chargeDetailList',
    name: 'chargeDetailList',
    component: () => import('@/views/charge/ChargeDetailList.vue')
  },
  {
    path: '/taskDetail',
    name: 'taskDetail',
    component: () => import('@/views/charge/TaskDetail.vue')
  },
  {
    path: '/charge',
    name: 'charge',
    component: () => import('@/views/charge/Charge.vue')
  },
  {
    path: '/auditDetails/:reportId',
    name: 'auditDetails',
    component: () => import('@/views/charge/AuditDetails.vue')
  },

  {
    path: '/auditResultList',
    name: 'auditResultList',
    component: () => import('@/views/auditResult/index.vue')
  },
  {
    path: '/auditResultDetail',
    name: 'auditResultDetail',
    component: () => import('@/views/auditResult/detail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
